import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutSurpriseCustom"
import BgImg from "../../../images/POHBig.jpg"
import PursuitofHappiness from "../../../images/POHSquareSeries.jpeg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="The Pursuit of Happiness"
    SecondText="Check out sermons from our Pursuit of Happiness Sermon Series"
  >
    <SEO title="Sermons - The Pursuit of Happiness" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
    <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="Joyful Community"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/joyful-community"
        date="June 27, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="The Good Life"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/the-good-life"
        date="June 20, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="Joyful Goals"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/joyful-goals"
        date="June 13, 2021"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="Joy-induced Knowledge"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/joy-induced-knowledge"
        date="June 6, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="Setting an Example"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/setting-an-example"
        date="May 30, 2021"
        pastor="Pastor Chad"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="Joy-revealing Light"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/joy-revealing-light"
        date="May 23, 2021"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="How to Make Your Pastor Happy"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/make-your-pastor-happy"
        date="May 16, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="Living and Dying"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/living-and-dying"
        date="May 9, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="What Has Happened To You?"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/what-happened"
        date="May 2, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="Joyful Partnership"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/joyful-partnership"
        date="April 25, 2021"
        pastor="Pastor Tyler"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="A Joyful Start"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/joyful-start"
        date="April 18, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={PursuitofHappiness}
        imageAlt="The Pursuit of Happiness Series"
        sermonTitle="Baptism Weekend"
        sermonSeries="The Pursuit of Happiness"
        sermonLink="/surprise/sermons/baptism-weekend"
        date="April 11, 2021"
        pastor="Pastor Jackie"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/surprise/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Surprise"
      />
    </Grid>
  </Layout>
)

export default IndexPage
